import React from 'react'
import { Layout, Seo } from '../components'
import { Download, IntroSubpage, TeamInsights } from '../sections/'
import { injectIntl, useIntl } from 'gatsby-plugin-intl'

const Team = () => {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }

    const IntroSubpageProps = {
        bg: '#F3EFFF',
        decorationsColor: [
            '#3D278A',
            'rgba(61, 39, 138, 0.6)',
            'rgba(61, 39, 138, 0.2)',
        ],
        title: t('team_intro_title'),
        description: t('team_intro_intro_message'),
    }
    return (
        <Layout>
            <Seo
                title={t('team_seo_title')}
                description={t('team_seo_description')}
            />
            <IntroSubpage {...IntroSubpageProps} />
            <TeamInsights />
            <Download />
        </Layout>
    )
}
export default injectIntl(Team)
